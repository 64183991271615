import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/blog',
    component: ComponentCreator('/blog', 'af8'),
    exact: true
  },
  {
    path: '/blog/ansible-benefits',
    component: ComponentCreator('/blog/ansible-benefits', '164'),
    exact: true
  },
  {
    path: '/blog/archive',
    component: ComponentCreator('/blog/archive', '182'),
    exact: true
  },
  {
    path: '/blog/authors',
    component: ComponentCreator('/blog/authors', '0b7'),
    exact: true
  },
  {
    path: '/blog/authors/suraj',
    component: ComponentCreator('/blog/authors/suraj', '63a'),
    exact: true
  },
  {
    path: '/blog/authors/suraj/authors/2',
    component: ComponentCreator('/blog/authors/suraj/authors/2', '59c'),
    exact: true
  },
  {
    path: '/blog/authors/suraj/authors/3',
    component: ComponentCreator('/blog/authors/suraj/authors/3', 'e4a'),
    exact: true
  },
  {
    path: '/blog/best-practices-for-ci-cd-pipelines',
    component: ComponentCreator('/blog/best-practices-for-ci-cd-pipelines', 'fa4'),
    exact: true
  },
  {
    path: '/blog/build-a-ci-cd-pipeline-effortlessly',
    component: ComponentCreator('/blog/build-a-ci-cd-pipeline-effortlessly', '9d3'),
    exact: true
  },
  {
    path: '/blog/cloud-consulting-business-partner',
    component: ComponentCreator('/blog/cloud-consulting-business-partner', '1fb'),
    exact: true
  },
  {
    path: '/blog/complete-chatbots-guide',
    component: ComponentCreator('/blog/complete-chatbots-guide', 'b37'),
    exact: true
  },
  {
    path: '/blog/containerization-in-devops',
    component: ComponentCreator('/blog/containerization-in-devops', '567'),
    exact: true
  },
  {
    path: '/blog/devops-challenges',
    component: ComponentCreator('/blog/devops-challenges', '999'),
    exact: true
  },
  {
    path: '/blog/embracing-artificial-intelligence-in-hospitality',
    component: ComponentCreator('/blog/embracing-artificial-intelligence-in-hospitality', 'c0a'),
    exact: true
  },
  {
    path: '/blog/infrastructure-as-code-for-gitops',
    component: ComponentCreator('/blog/infrastructure-as-code-for-gitops', 'cb7'),
    exact: true
  },
  {
    path: '/blog/langchain-conversational-ai',
    component: ComponentCreator('/blog/langchain-conversational-ai', '662'),
    exact: true
  },
  {
    path: '/blog/make-intelligent-chatbot',
    component: ComponentCreator('/blog/make-intelligent-chatbot', '11d'),
    exact: true
  },
  {
    path: '/blog/microservices-architecture-in-2024',
    component: ComponentCreator('/blog/microservices-architecture-in-2024', '2a9'),
    exact: true
  },
  {
    path: '/blog/microservices-in-ecomm',
    component: ComponentCreator('/blog/microservices-in-ecomm', 'f60'),
    exact: true
  },
  {
    path: '/blog/openai-llm-in-chatbots',
    component: ComponentCreator('/blog/openai-llm-in-chatbots', '291'),
    exact: true
  },
  {
    path: '/blog/page/2',
    component: ComponentCreator('/blog/page/2', 'c33'),
    exact: true
  },
  {
    path: '/blog/page/3',
    component: ComponentCreator('/blog/page/3', '4f6'),
    exact: true
  },
  {
    path: '/blog/retrieval-augmented-generation-introduction',
    component: ComponentCreator('/blog/retrieval-augmented-generation-introduction', 'cbf'),
    exact: true
  },
  {
    path: '/blog/serverless-architecture-computing',
    component: ComponentCreator('/blog/serverless-architecture-computing', 'c15'),
    exact: true
  },
  {
    path: '/blog/tags',
    component: ComponentCreator('/blog/tags', '287'),
    exact: true
  },
  {
    path: '/blog/tags/ai',
    component: ComponentCreator('/blog/tags/ai', 'c99'),
    exact: true
  },
  {
    path: '/blog/tags/aws',
    component: ComponentCreator('/blog/tags/aws', '4b2'),
    exact: true
  },
  {
    path: '/blog/tags/business',
    component: ComponentCreator('/blog/tags/business', '9a1'),
    exact: true
  },
  {
    path: '/blog/tags/chatbot',
    component: ComponentCreator('/blog/tags/chatbot', '771'),
    exact: true
  },
  {
    path: '/blog/tags/cloud',
    component: ComponentCreator('/blog/tags/cloud', '7ae'),
    exact: true
  },
  {
    path: '/blog/tags/collaboration',
    component: ComponentCreator('/blog/tags/collaboration', '297'),
    exact: true
  },
  {
    path: '/blog/tags/development',
    component: ComponentCreator('/blog/tags/development', '269'),
    exact: true
  },
  {
    path: '/blog/tags/devops',
    component: ComponentCreator('/blog/tags/devops', '78b'),
    exact: true
  },
  {
    path: '/blog/tags/devops/page/2',
    component: ComponentCreator('/blog/tags/devops/page/2', '876'),
    exact: true
  },
  {
    path: '/blog/tags/docker',
    component: ComponentCreator('/blog/tags/docker', '42d'),
    exact: true
  },
  {
    path: '/blog/tags/iac',
    component: ComponentCreator('/blog/tags/iac', '693'),
    exact: true
  },
  {
    path: '/blog/tags/jenkins',
    component: ComponentCreator('/blog/tags/jenkins', '2d4'),
    exact: true
  },
  {
    path: '/blog/tags/js',
    component: ComponentCreator('/blog/tags/js', '1a6'),
    exact: true
  },
  {
    path: '/blog/tags/kubernetes',
    component: ComponentCreator('/blog/tags/kubernetes', '385'),
    exact: true
  },
  {
    path: '/blog/tags/langchain',
    component: ComponentCreator('/blog/tags/langchain', 'a57'),
    exact: true
  },
  {
    path: '/blog/tags/llm',
    component: ComponentCreator('/blog/tags/llm', 'e08'),
    exact: true
  },
  {
    path: '/blog/tags/microservices',
    component: ComponentCreator('/blog/tags/microservices', '124'),
    exact: true
  },
  {
    path: '/blog/tags/ml',
    component: ComponentCreator('/blog/tags/ml', '7c4'),
    exact: true
  },
  {
    path: '/blog/tags/rag',
    component: ComponentCreator('/blog/tags/rag', 'cd3'),
    exact: true
  },
  {
    path: '/blog/tags/react',
    component: ComponentCreator('/blog/tags/react', 'b85'),
    exact: true
  },
  {
    path: '/blog/tags/vue',
    component: ComponentCreator('/blog/tags/vue', 'd40'),
    exact: true
  },
  {
    path: '/blog/top-5-essential-devops-tools',
    component: ComponentCreator('/blog/top-5-essential-devops-tools', '71c'),
    exact: true
  },
  {
    path: '/blog/understanding-langchain',
    component: ComponentCreator('/blog/understanding-langchain', '438'),
    exact: true
  },
  {
    path: '/blog/understanding-machine-learning',
    component: ComponentCreator('/blog/understanding-machine-learning', '2b4'),
    exact: true
  },
  {
    path: '/blog/understanding-react-hooks',
    component: ComponentCreator('/blog/understanding-react-hooks', '988'),
    exact: true
  },
  {
    path: '/blog/vuejs-chatbot-development',
    component: ComponentCreator('/blog/vuejs-chatbot-development', '05c'),
    exact: true
  },
  {
    path: '/blog/websockets-vs-SSE',
    component: ComponentCreator('/blog/websockets-vs-SSE', '957'),
    exact: true
  },
  {
    path: '/blog/what-is-fastapi-and-llm',
    component: ComponentCreator('/blog/what-is-fastapi-and-llm', 'ebb'),
    exact: true
  },
  {
    path: '/contact',
    component: ComponentCreator('/contact', 'abe'),
    exact: true
  },
  {
    path: '/markdown-page',
    component: ComponentCreator('/markdown-page', '3d7'),
    exact: true
  },
  {
    path: '/privacy-policy',
    component: ComponentCreator('/privacy-policy', '59c'),
    exact: true
  },
  {
    path: '/search',
    component: ComponentCreator('/search', '5de'),
    exact: true
  },
  {
    path: '/terms-of-service',
    component: ComponentCreator('/terms-of-service', '00f'),
    exact: true
  },
  {
    path: '/docs',
    component: ComponentCreator('/docs', '1ff'),
    routes: [
      {
        path: '/docs',
        component: ComponentCreator('/docs', 'ad8'),
        routes: [
          {
            path: '/docs/tags',
            component: ComponentCreator('/docs/tags', 'fce'),
            exact: true
          },
          {
            path: '/docs/tags/ai',
            component: ComponentCreator('/docs/tags/ai', 'bd3'),
            exact: true
          },
          {
            path: '/docs/tags/aws',
            component: ComponentCreator('/docs/tags/aws', 'e55'),
            exact: true
          },
          {
            path: '/docs/tags/dev-ops',
            component: ComponentCreator('/docs/tags/dev-ops', 'd77'),
            exact: true
          },
          {
            path: '/docs/tags/ia-c',
            component: ComponentCreator('/docs/tags/ia-c', 'af9'),
            exact: true
          },
          {
            path: '/docs/tags/k-8-s',
            component: ComponentCreator('/docs/tags/k-8-s', '2bf'),
            exact: true
          },
          {
            path: '/docs/tags/llm',
            component: ComponentCreator('/docs/tags/llm', 'cef'),
            exact: true
          },
          {
            path: '/docs',
            component: ComponentCreator('/docs', '032'),
            routes: [
              {
                path: '/docs/AWS/deploying-an-application-in-aws-lambda',
                component: ComponentCreator('/docs/AWS/deploying-an-application-in-aws-lambda', 'da6'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Kubernetes/kubernetes-installation-and-setup',
                component: ComponentCreator('/docs/Kubernetes/kubernetes-installation-and-setup', 'ab3'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/LLM/deploying-llm-using-fastapi-in-docker',
                component: ComponentCreator('/docs/LLM/deploying-llm-using-fastapi-in-docker', '807'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Terraform/deploying-an-application-in-ec2-using-terraform',
                component: ComponentCreator('/docs/Terraform/deploying-an-application-in-ec2-using-terraform', 'fcc'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/Terraform/terraform-installation',
                component: ComponentCreator('/docs/Terraform/terraform-installation', '5c6'),
                exact: true,
                sidebar: "tutorialSidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: ComponentCreator('/', '2e1'),
    exact: true
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
