/*
 * AUTOGENERATED - DON'T EDIT
 * Your edits in this file will be overwritten in the next build!
 * Modify the docusaurus.config.js file at your site's root instead.
 */
export default {
  "title": "Leading IT Services & Software Product Development Partner | Tristiks Consulting",
  "tagline": "AI . Cloud . DevOps",
  "favicon": "img/favicon.ico",
  "url": "https://tristiks.com",
  "baseUrl": "/",
  "stylesheets": [
    {
      "href": "https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Manrope:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap",
      "type": "text/css"
    }
  ],
  "headTags": [
    {
      "tagName": "link",
      "attributes": {
        "rel": "stylesheet",
        "href": "https://cdn-uicons.flaticon.com/2.6.0/uicons-brands/css/uicons-brands.css"
      }
    },
    {
      "tagName": "script",
      "attributes": {
        "id": "organizationSchema",
        "type": "application/ld+json",
        "data-nscript": "afterInteractive"
      },
      "innerHTML": "\n      {\"@context\":\"https://schema.org\",\"@type\":\"Organization\",\"name\":\"Tristiks Consulting\",\"url\":\"https://tristiks.com\",\"logo\":{\"@type\":\"ImageObject\",\"url\":\"https://tristiks.com/img/tristiks_consulting.svg\"},\"sameAs\":[\"https://www.youtube.com/@tristiks\",\"https://www.facebook.com/tristiks\",\"https://www.instagram.com/tristiks_consulting\",\"https://x.com/tristiks_ltd\",\"https://www.linkedin.com/company/tristiks\"],\"contactPoint\":[{\"@type\":\"ContactPoint\",\"name\":\"Customer Support\",\"url\":\"https://tristiks.com/contact\",\"telephone\":\"+919380143709\",\"email\":\"hello@tristiks.com\"},{\"@type\":\"ContactPoint\",\"name\":\"Customer Support\",\"telephone\":\"+919380143709\"}]}\n      "
    }
  ],
  "organizationName": "Tristiks Consulting",
  "projectName": "tristiks-website",
  "onBrokenLinks": "throw",
  "onBrokenMarkdownLinks": "warn",
  "i18n": {
    "defaultLocale": "en",
    "locales": [
      "en"
    ],
    "path": "i18n",
    "localeConfigs": {}
  },
  "markdown": {
    "mermaid": true,
    "format": "mdx",
    "mdx1Compat": {
      "comments": true,
      "admonitions": true,
      "headingIds": true
    },
    "anchors": {
      "maintainCase": false
    }
  },
  "themes": [
    "@docusaurus/theme-mermaid"
  ],
  "plugins": [
    null
  ],
  "presets": [
    [
      "classic",
      {
        "gtag": {
          "trackingID": "G-SH7JBNE85E",
          "anonymizeIP": true
        },
        "docs": {
          "sidebarPath": "./sidebars.js"
        },
        "sitemap": {
          "lastmod": "datetime",
          "changefreq": "daily",
          "priority": 0.7,
          "ignorePatterns": [
            "/blog/tags/**",
            "/docs/tags/**",
            "/markdown-page"
          ],
          "filename": "sitemap.xml"
        },
        "blog": {
          "blogTitle": "Tristiks Consulting Blog | Insights on AI, ML, Product Design & Product Development",
          "blogDescription": "Automate your processes and accelerate your business growth with Tristiks' scalable, secure, custom software product development solutions.",
          "blogSidebarTitle": "Latest Blogs",
          "blogSidebarCount": 5,
          "postsPerPage": 9,
          "showReadingTime": true,
          "feedOptions": {
            "type": [
              "rss",
              "atom"
            ],
            "xslt": true
          },
          "onInlineTags": "warn",
          "onInlineAuthors": "warn",
          "onUntruncatedBlogPosts": "warn"
        },
        "theme": {
          "customCss": "./src/css/custom.css"
        }
      }
    ]
  ],
  "themeConfig": {
    "image": "img/social-card.png",
    "colorMode": {
      "defaultMode": "dark",
      "disableSwitch": false,
      "respectPrefersColorScheme": false
    },
    "metadata": [
      {
        "property": "og:site_name",
        "content": "Tristiks Consulting"
      },
      {
        "property": "og:title",
        "content": "Leading IT Services & Software Product Development Partner | Tristiks Consulting"
      },
      {
        "property": "og:type",
        "content": "website"
      }
    ],
    "algolia": {
      "appId": "8ABVUIICXM",
      "apiKey": "f71b675eaf8242160e18e3c4608c2687",
      "indexName": "tristikstech",
      "contextualSearch": true,
      "searchParameters": {},
      "searchPagePath": "search"
    },
    "navbar": {
      "title": "Tristiks",
      "hideOnScroll": true,
      "logo": {
        "alt": "Tristiks Consulting",
        "src": "img/tristiks_consulting.svg"
      },
      "items": [
        {
          "label": "Services",
          "position": "left",
          "items": [
            {
              "to": "#",
              "label": "Web App Development"
            },
            {
              "to": "#",
              "label": "Mobile App Development"
            },
            {
              "to": "#",
              "label": "Artificial Intelligence"
            },
            {
              "to": "#",
              "label": "DevOps Solutions"
            },
            {
              "to": "#",
              "label": "Chatbot Development"
            }
          ]
        },
        {
          "to": "#",
          "label": "AWS",
          "position": "left",
          "exact": "true"
        },
        {
          "label": "About Us",
          "position": "left",
          "items": [
            {
              "to": "#",
              "label": "Our Story"
            },
            {
              "to": "#",
              "label": "How We Work"
            },
            {
              "to": "#",
              "label": "Leadership Team"
            },
            {
              "to": "#",
              "label": "Partners"
            },
            {
              "to": "#",
              "label": "Client Success Stories"
            },
            {
              "to": "#",
              "label": "In News"
            }
          ]
        },
        {
          "label": "Insights",
          "position": "left",
          "items": [
            {
              "to": "/blog",
              "label": "Blogs",
              "exact": true
            },
            {
              "to": "#",
              "label": "Case Studies",
              "exact": "true"
            },
            {
              "to": "#",
              "label": "Videos",
              "exact": "true"
            }
          ]
        },
        {
          "to": "#",
          "label": "Careers",
          "position": "left",
          "exact": "true"
        },
        {
          "to": "/contact",
          "label": "💬 Get In Touch",
          "position": "right"
        },
        {
          "type": "search",
          "position": "right"
        }
      ]
    },
    "footer": {
      "style": "dark",
      "links": [
        {
          "title": "Say Hello",
          "items": [
            {
              "label": "hello@tristiks.com",
              "href": "mailto:hello@tristiks.com"
            },
            {
              "label": "careers@tristiks.com",
              "href": "mailto:careers@tristiks.com"
            }
          ]
        },
        {
          "title": "Community",
          "items": [
            {
              "html": "<a class=\"social\" href=\"https://www.instagram.com/tristiks_consulting\" target=\"_blank\"><i class=\"fi fi-brands-instagram\"></i> Instagram</a>"
            },
            {
              "html": "<a class=\"social\" href=\"https://www.linkedin.com/company/tristiks/\" target=\"_blank\"><i class=\"fi fi-brands-linkedin\"></i> Linkedin</a>"
            },
            {
              "html": "<a class=\"social\" href=\"https://www.youtube.com/@tristiks\" target=\"_blank\"><i class=\"fi fi-brands-youtube\"></i> YouTube</a>"
            }
          ]
        },
        {
          "title": "Company",
          "items": [
            {
              "label": "About Us",
              "to": "#",
              "aria-label": "Know more About our company."
            },
            {
              "label": "Careers",
              "to": "#",
              "aria-label": "We're constantly growing, and you can too."
            },
            {
              "label": "Blog",
              "to": "/blog",
              "aria-label": "Checkout our blog posts."
            },
            {
              "label": "Contact Us",
              "to": "/contact",
              "aria-label": "Contact us know for any queries."
            }
          ]
        },
        {
          "title": "More",
          "items": [
            {
              "label": "Privacy policy",
              "to": "/privacy-policy",
              "aria-label": "View our Privacy Policy"
            },
            {
              "label": "Terms of service",
              "to": "/terms-of-service",
              "aria-label": "Read our Terms of Service"
            },
            {
              "label": "Search",
              "to": "/search"
            }
          ]
        }
      ],
      "logo": {
        "alt": "Tristiks Consulting",
        "src": "img/tristiks_consulting.svg",
        "href": "/",
        "width": 50,
        "height": 50
      },
      "copyright": "Copyright © 2025. Tristiks Consulting. All rights reserved."
    },
    "prism": {
      "theme": {
        "plain": {
          "color": "#393A34",
          "backgroundColor": "#f6f8fa"
        },
        "styles": [
          {
            "types": [
              "comment",
              "prolog",
              "doctype",
              "cdata"
            ],
            "style": {
              "color": "#999988",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "namespace"
            ],
            "style": {
              "opacity": 0.7
            }
          },
          {
            "types": [
              "string",
              "attr-value"
            ],
            "style": {
              "color": "#e3116c"
            }
          },
          {
            "types": [
              "punctuation",
              "operator"
            ],
            "style": {
              "color": "#393A34"
            }
          },
          {
            "types": [
              "entity",
              "url",
              "symbol",
              "number",
              "boolean",
              "variable",
              "constant",
              "property",
              "regex",
              "inserted"
            ],
            "style": {
              "color": "#36acaa"
            }
          },
          {
            "types": [
              "atrule",
              "keyword",
              "attr-name",
              "selector"
            ],
            "style": {
              "color": "#00a4db"
            }
          },
          {
            "types": [
              "function",
              "deleted",
              "tag"
            ],
            "style": {
              "color": "#d73a49"
            }
          },
          {
            "types": [
              "function-variable"
            ],
            "style": {
              "color": "#6f42c1"
            }
          },
          {
            "types": [
              "tag",
              "selector",
              "keyword"
            ],
            "style": {
              "color": "#00009f"
            }
          }
        ]
      },
      "darkTheme": {
        "plain": {
          "color": "#F8F8F2",
          "backgroundColor": "#282A36"
        },
        "styles": [
          {
            "types": [
              "prolog",
              "constant",
              "builtin"
            ],
            "style": {
              "color": "rgb(189, 147, 249)"
            }
          },
          {
            "types": [
              "inserted",
              "function"
            ],
            "style": {
              "color": "rgb(80, 250, 123)"
            }
          },
          {
            "types": [
              "deleted"
            ],
            "style": {
              "color": "rgb(255, 85, 85)"
            }
          },
          {
            "types": [
              "changed"
            ],
            "style": {
              "color": "rgb(255, 184, 108)"
            }
          },
          {
            "types": [
              "punctuation",
              "symbol"
            ],
            "style": {
              "color": "rgb(248, 248, 242)"
            }
          },
          {
            "types": [
              "string",
              "char",
              "tag",
              "selector"
            ],
            "style": {
              "color": "rgb(255, 121, 198)"
            }
          },
          {
            "types": [
              "keyword",
              "variable"
            ],
            "style": {
              "color": "rgb(189, 147, 249)",
              "fontStyle": "italic"
            }
          },
          {
            "types": [
              "comment"
            ],
            "style": {
              "color": "rgb(98, 114, 164)"
            }
          },
          {
            "types": [
              "attr-name"
            ],
            "style": {
              "color": "rgb(241, 250, 140)"
            }
          }
        ]
      },
      "additionalLanguages": [],
      "magicComments": [
        {
          "className": "theme-code-block-highlighted-line",
          "line": "highlight-next-line",
          "block": {
            "start": "highlight-start",
            "end": "highlight-end"
          }
        }
      ]
    },
    "docs": {
      "versionPersistence": "localStorage",
      "sidebar": {
        "hideable": false,
        "autoCollapseCategories": false
      }
    },
    "blog": {
      "sidebar": {
        "groupByYear": true
      }
    },
    "tableOfContents": {
      "minHeadingLevel": 2,
      "maxHeadingLevel": 3
    },
    "mermaid": {
      "theme": {
        "dark": "dark",
        "light": "default"
      },
      "options": {}
    }
  },
  "baseUrlIssueBanner": true,
  "future": {
    "experimental_storage": {
      "type": "localStorage",
      "namespace": false
    },
    "experimental_router": "browser"
  },
  "onBrokenAnchors": "warn",
  "onDuplicateRoutes": "warn",
  "staticDirectories": [
    "static"
  ],
  "customFields": {},
  "scripts": [],
  "clientModules": [],
  "titleDelimiter": "|",
  "noIndex": false
};
